import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "private-registry"
    }}>{`Private registry`}</h1>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h2>
    <p>{`Our `}<a parentName="p" {...{
        "href": "https://registry.wild.plus"
      }}>{`private registry`}</a>{` is developed with `}<a parentName="p" {...{
        "href": "https://verdaccio.org"
      }}>{`Verdaccio`}</a>{`, a lightweight open-source private npm proxy registry.`}<br parentName="p"></br>{`
`}{`It contains every package distributed and used internally (unless there is a need to make it public).  `}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`To authenticate, you need to add the private registry to your local `}<inlineCode parentName="p">{`~/.npmrc`}</inlineCode>{` file`}</p>
    <p>{`To do this, please follow these instructions:`}</p>
    <ol>
      <li parentName="ol">{`Go to `}<a parentName="li" {...{
          "href": "https://registry.wild.plus"
        }}>{`https://registry.wild.plus`}</a></li>
      <li parentName="ol">{`Click on Login`}</li>
      <li parentName="ol">{`Grant permissions to the Github application (you need to be part of our `}<a parentName="li" {...{
          "href": "https://github.com/madebywild"
        }}>{`Github organization`}</a>{`)`}</li>
    </ol>
    <p><img alt="./github-auth.png" src={require("./github-auth.png")} /></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click the Info button at the top`}</li>
    </ol>
    <p><img alt="./info.png" src={require("./info.png")} /></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`The overlay now shows three commands you need to run locally, one after another`}</li>
    </ol>
    <p><img alt="./info.png" src={require("./registry-commands.png")} /></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Your local `}<inlineCode parentName="li">{`~/.npmrc`}</inlineCode>{` file should now contain these lines:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`registry=https://registry.wild.plus/
//registry.wild.plus/:_authToken="{TOKEN}"
//registry.wild.plus/:always-auth true
`}</code></pre>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`And you're all done, now you can install private or newer packages in the `}<inlineCode parentName="li">{`@madebywild/*`}</inlineCode>{` namespace from the private registry`}</li>
    </ol>
    <h2 {...{
      "id": "publishing"
    }}>{`Publishing`}</h2>
    <p>{`Once authenticated, there are no limitations of any kind, you can publish and unpublish packages with the `}<inlineCode parentName="p">{`npm publish`}</inlineCode>{` and `}<inlineCode parentName="p">{`npm unpublish`}</inlineCode>{` commands.
Obviously, use them responsibly.`}</p>
    <h2 {...{
      "id": "naming-convention"
    }}>{`Naming convention`}</h2>
    <p>{`Internal packages use the `}<inlineCode parentName="p">{`@madebywild/*`}</inlineCode>{` namespace.`}</p>
    <h2 {...{
      "id": "what-about-our-npm-packages"
    }}>{`What about our NPM packages?`}</h2>
    <p>{`Verdaccio works as a proxy to the NPM registry, meaning that:`}</p>
    <ul>
      <li parentName="ul">{`if a `}<inlineCode parentName="li">{`@madebywild/*`}</inlineCode>{` package is published on both Verdaccio and NPM registries, Verdaccio takes precedence.`}</li>
      <li parentName="ul">{`if a `}<inlineCode parentName="li">{`@madebywild/*`}</inlineCode>{` package is published only on NPM, it will be normally used.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      